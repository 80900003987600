import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../stylesheets/404.scss"


const NotFoundPage = () => (
  <Layout>
    <div className="notfound">
    <div className="container">
      <div className="row">    
        <div class="col d-flex align-items-center bd-highlight mb-3">
  

<SEO title="404: Not found" />
        <h1>404: Not Found</h1>

       
          </div>
        </div>
      </div>
    </div>

  </Layout>
)

export default NotFoundPage
